export const environment = {
  amazonS3Url: "https://s3.ca-central-1.amazonaws.com/dev.weemove.ca/",
  amazonS3: {
    accessKeyId: "AKIASDPDK6KUTL24VXYZ",
    secretAccessKey: "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",
  },
  apiUrl: "https://devopsapi.hivenue.ca/",
  fbAppId: "918466992410619",
  googleClientId:
    "319389181819-04sqcctub6hog8rpa7nscupihtjlbc1r.apps.googleusercontent.com",
  googleLocationAPIKey: "AIzaSyCrhAwFqZXA2urgb84AWQlFo-RCg-X49vA",
  production: false,
  stripePublishableKey:
    "pk_test_51N7h7RAVT9RHsNRAyO8lxqxipuslb8hyT9ahcyHoo2ETNN3fQBLVsqKHq7cAoIpzlLU1IjN78KV1cJoufky7UaYO00yrN6enOn",
  adminEmail: "nassimmontreal@gmail.com",
  branchName: "Hivenue/riipen-frontend@develop: 2025-02-18, Tue 01:22 AM UTC", // used by github actions in order to display the current branch
};
